<template>
  <v-card>
    <v-card-title class="text-h5" primary-title>
      <h6 class="text-h6">
        {{ $trans("add_to_group") }}
      </h6>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-container class="mt-2">
        <v-row>
          <v-col>
            <v-select
              v-model="selectedGroups"
              :items="groupsFilter"
              chips
              deletable-chips
              :height="selectedGroups.length > 0 ? undefined : 10"
              multiple
              :label="$trans('groups')"
              outlined
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="light" text :disabled="isLoading" @click="$emit('close')">
        {{ $trans("cancel") }}
      </v-btn>
      <v-btn
        :disabled="isLoading || selectedGroups.length === 0"
        color="accent_light"
        text
        @click="updateUserGroup"
      >
        OK
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import {
  minChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import userActions from "@/calendesk/mixins/userActions";

export default {
  name: "AddToGroupDialog",
  mixins: [userActions],
  data() {
    return {
      rules: {
        required,
        minChars,
      },
      selectedGroups: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getGroups: "group/groups",
      selectedUser: "user/getSelectedUser",
    }),
    groupsFilter() {
      if (this.getGroups) {
        return this.getGroups.map((el) => ({
          text: el.name,
          value: el.id,
        }));
      }

      return [];
    },
  },
  mounted() {
    this.fetchGroups();
  },
  methods: {
    ...mapActions({
      updateUser: "user/update",
      getUser: "user/get",
      fetchGroups: "group/fetchAll",
      refreshUsers: "user/refreshUsers",
    }),
    async updateUserGroup() {
      if (this.selectedGroups.length) {
        this.$emit("loading", true);
        this.isLoading = true;
        const vm = this;
        const usersPromises = [];
        const usersID = this.getConfirmDialog.data;
        const groupsToAdd = this.selectedGroups;

        for (let i = 0; i < usersID.length; i++) {
          usersPromises.push(vm.getUser(usersID[i].id));
        }
        const updateUsers = (users) => {
          const updatePromises = [];
          for (let i = 0; i < users.length; i++) {
            const groups = users[i].groups.map((el) => el.id);
            const all = [...groupsToAdd, ...groups];
            const unique = [...new Set(all)];
            updatePromises.push(
              vm.updateUser({ groups: unique, id: users[i].id })
            );
          }
          Promise.all(updatePromises)
            .then(() => {
              successNotification("accounts_update_succeeded");
              this.isLoading = false;
              this.$emit("loading", false);
              this.$emit("close");
              vm.refreshUsers();
            })
            .catch((err) => {
              errorNotification("accounts_update_failed", err);
              this.isLoading = false;
              this.$emit("loading", false);
              this.$emit("close");
            });
        };

        Promise.all(usersPromises)
          .then((res) => updateUsers(res))
          .catch((err) => {
            errorNotification("accounts_update_failed", err);
            this.isLoading = false;
            this.$emit("loading", false);
            this.$emit("close");
            console.log(err);
          });
      }
    },
  },
};
</script>
